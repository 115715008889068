<template>
  <ul class="flex flex-col gap-2">
    <AppListItem type="checkmark">
      <span class="font-medium">25&nbsp;&#037; Rabatt auf jeden Arztbesuch:</span> Spare bei jedem Arztbesuch
    </AppListItem>
    <AppListItem type="checkmark">
      <span class="font-medium">Hohe Rückerstattung:</span> Erhalte bis zu 45&nbsp;&#037; von der Krankenkasse zurück
    </AppListItem>
    <AppListItem type="checkmark">
      <span class="font-medium">Familienmitgliedschaft:</span> 25&nbsp;&#037; auf Mitgliedschaften für deine Familie
    </AppListItem>
    <slot />
  </ul>
</template>

<script setup lang="ts"></script>

<style scoped></style>
